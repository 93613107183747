import React from "react";

export const Arrow = () => (
  <svg
    viewBox="0 0 59 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: "1em" }}
    className="arrow"
  >
    <path
      d="M58.0607 13.0607C58.6464 12.4749 58.6464 11.5251 58.0607 10.9393L48.5147 1.3934C47.9289 0.807611 46.9792 0.807611 46.3934 1.3934C45.8076 1.97919 45.8076 2.92893 46.3934 3.51472L54.8787 12L46.3934 20.4853C45.8076 21.0711 45.8076 22.0208 46.3934 22.6066C46.9792 23.1924 47.9289 23.1924 48.5147 22.6066L58.0607 13.0607ZM0 13.5H57V10.5H0V13.5Z"
      fill="currentColor"
    />
  </svg>
);
